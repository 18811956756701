<!--
 * @description: 
 * @param: 
 * @return: 
 * @Author: zrh
 * @Date: 2024-02-07 13:46:44
 * @LastEditors: sueRimn
 * @LastEditTime: 2024-02-19 15:07:13
-->
<!--/*
 * @file           UserCenter
 * @description    用户中心
 * @author         zrh
 * @date            2024-02-07
 */ -->
<template>
  <div class="container">
    <div class="header">
      <span class="headerTitle">用户中心</span>
      <div class="line"></div>
    </div>
    <div class="content">
      <el-tabs tab-position="left" style="height: 950px" v-model="activeName">
        <el-tab-pane
          label="个人资料"
          @tab-click="handleTabChange"
          name="introduce"
        >
          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            class="personalData formCenter"
          >
            <el-form-item label="头像">
              <el-upload
                action="#"
                :on-preview="handlePictureCardPreview"
                :http-request="handleAvatarUpload"
                :on-remove="handleRemove"
                enctype="multipart/form-data"
                :show-file-list="false"
              >
                <div class="avatar">
                  <img :src="form.avatar" alt="" v-if="form.avatar != ''" />
                  <img src="../assets/images/defaultAvatar.jpg" v-else />
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input v-model="form.nickName" disabled></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="form.email" disabled></el-input>
            </el-form-item>
            <el-form-item label="真实姓名">
              <el-input v-model="form.realName"></el-input>
            </el-form-item>
            <el-form-item label="所在机构">
              <el-input v-model="form.organization"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-select v-model="form.sex" placeholder="性别">
                <el-option label="男" value="0"></el-option>
                <el-option label="女" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生日">
              <el-col :span="11">
                <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="宗教">
              <el-select v-model="form.religion" placeholder="宗教">
                <el-option
                  v-for="(item, index) in optionList.religious"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="居住地">
              <el-select v-model="form.residence" placeholder="中国">
                <el-option
                  v-for="(item, index) in optionList.regions"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学历">
              <el-select v-model="form.education" placeholder="学历">
                <el-option
                  v-for="(item, index) in optionList.educations"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职业">
              <el-select v-model="form.occupation" placeholder="职业">
                <el-option
                  v-for="(item, index) in optionList.occupations"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onSubmitUserInfo"
                style="background-color: #83111e; color: #fff"
                >提交修改</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="账户安全" name="security">
          <el-form
            ref="form"
            :model="form"
            label-width="82px"
            class="formCenter"
          >
            <el-form-item label="旧密码">
              <el-input
                v-model="passwordForm.oldPassword"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input
                v-model="passwordForm.newPassword"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认新密码">
              <el-input
                v-model="passwordForm.conformPassword"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onSubmitPasswordMessage"
                style="background-color: #83111e; color: #fff"
                >提交修改</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="成为作者" v-if="identify == '0'" name="toBeAuthor">
          <div class="tip">补充下列信息，点击提交即可成为作者</div>
          <el-form
            ref="authorForm"
            :model="authorForm"
            :rules="rules"
            label-width="80px"
            class="formCenter"
          >
            <el-form-item label="性别" prop="sex">
              <el-select v-model="authorForm.sex" placeholder="性别">
                <el-option label="男" value="0"></el-option>
                <el-option label="女" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生日" prop="birthday">
              <el-col :span="11">
                <el-date-picker
                  v-model="authorForm.birthday"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="宗教" prop="religion">
              <el-select v-model="authorForm.religion" placeholder="宗教">
                <el-option
                  v-for="(item, index) in optionList.religious"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="居住地" prop="residence">
              <el-select v-model="authorForm.residence" placeholder="中国">
                <el-option
                  v-for="(item, index) in optionList.regions"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学历" prop="education">
              <el-select v-model="authorForm.education" placeholder="学历">
                <el-option
                  v-for="(item, index) in optionList.educations"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职业" prop="occupation">
              <el-select v-model="authorForm.occupation" placeholder="职业">
                <el-option
                  v-for="(item, index) in optionList.occupations"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="真实姓名" prop="realName">
              <el-input v-model="authorForm.realName"></el-input>
            </el-form-item>
            <el-form-item label="所在机构" prop="organization">
              <el-input v-model="authorForm.organization"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onSubmitToBecomeAuthor"
                style="
                  background-color: #83111e;
                  color: #fff;
                  border-color: #83111e;
                "
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          label="发起投稿"
          v-if="identify == '1'"
          name="initializeContribute"
          ><ContributeArticle @submit="changeIdentifyByContributeTableFinish"
        /></el-tab-pane>
        <el-tab-pane
          label="我的投稿"
          v-if="identify == '1'"
          name="contributeList"
        >
          <ContributeTable ref="contributeTable" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ContributeArticle from "../components/ContributeArticle.vue";
import ContributeTable from "../components/ContributeTable.vue";
export default {
  components: {
    ContributeArticle,
    ContributeTable,
  },
  data() {
    return {
      //  用户信息的表单
      form: {
        name: "",
        avatar: "",
        email: "",
      },
      // 成为作者的表单信息
      authorForm: {
        sex: "",
        birthday: "",
        religion: "",
        residence: "",
        education: "",
        occupation: "",
        realName: "",
        organization: "",
      },
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        conformPassword: "",
      },
      identify: -1, // 0是普通用户  1是作者用户
      optionList: {
        religious: [],
        regions: [],
        educations: [],
        occupations: [],
      },
      rules: {
        sex: [{ required: true, message: "请输入性别", trigger: "blur" }],
        birthday: [
          { required: true, message: "请输入出生年月", trigger: "blur" },
        ],
        religion: [{ required: true, message: "请输入宗教", trigger: "blur" }],
        education: [{ required: true, message: "请输入学历", trigger: "blur" }],
        occupation: [
          { required: true, message: "请输入职业", trigger: "blur" },
        ],
        residence: [{ required: true, message: "请输入性别", trigger: "blur" }],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        organization: [
          { required: true, message: "请输入所在机构", trigger: "blur" },
        ],
      },
      activeName: "introduce",
    };
  },
  created() {},
  mounted() {
    this.init();
    this.getCurrentUserMessageToLocalStorage();
  },
  methods: {
    async judgeLogin() {
      const res = await this.$http.get("/user/profile/get");
      if (res.data.code == 401) {
        this.$message({
          message: "登录信息已过期或未登录,请重新登录",
          type: "error",
        });
        return false;
      }
      return true;
    },

    //
    changeIdentifyByContributeTableFinish() {
      this.activeName = "contributeList";
      this.$refs.contributeTable.getTableList();
    },
    async onSubmitUserInfo() {
      const { data: res } = await this.$http.post(
        "/user/profile/update",
        this.form
      );
      if (res.code == "SUCCESS") {
        this.$message.success("修改成功");
        // 获取新的修改内容 放到local中
        this.getCurrentUserMessageToLocalStorage();
        // 重新赋值
        this.init();
      } else {
        this.$message.warning(res.message);
      }
    },
    // 获取全部学历
    async getAllEducations() {
      const { data: res } = await this.$http.get("/common/getAllEducations");
      console.log("res", res);
      if (res.code == "SUCCESS") {
        this.optionList.educations = res.data;
      } else {
        this.$message.warning(res.message);
      }
    },
    // 获取全部职业
    async getAllOccupation() {
      const { data: res } = await this.$http.get("/common/getAllOccupations");
      console.log("res", res);
      if (res.code == "SUCCESS") {
        this.optionList.occupations = res.data;
      } else {
        this.$message.warning(res.message);
      }
    },
    // 获取全部宗教
    async getAllReligious() {
      const { data: res } = await this.$http.get("/common/getAllReligions");
      console.log("res", res);
      if (res.code == "SUCCESS") {
        this.optionList.religious = res.data;
      } else {
        this.$message.warning(res.message);
      }
    },
    // 获取全部地区
    async getAllRegions() {
      const { data: res } = await this.$http.get("/common/getAllRegions");
      console.log("res", res);
      if (res.code == "SUCCESS") {
        this.optionList.regions = res.data;
      } else {
        this.$message.warning(res.message);
      }
    },
    // Tab栏切换
    handleTabChange() {
      // 清空 第二个模块 的输入信息
      this.passwordForm.conformPassword = "";
      this.passwordForm.newPassword = "";
      this.passwordForm.oldPassword = "";
    },
    async onSubmitPasswordMessage() {
      if (this.passwordForm.conformPassword != this.passwordForm.newPassword) {
        this.$message.warning("新密码不一致");
        return;
      } else {
        const { data: res } = await this.$http.post("/user/profile/updatePwd", {
          newPassword: this.passwordForm.newPassword,
          oldPassword: this.passwordForm.oldPassword,
        });
        if (res.code == "SUCCESS") {
          this.$message.success("密码修改成功");
          this.passwordForm.conformPassword = "";
          this.passwordForm.newPassword = "";
          this.passwordForm.oldPassword = "";
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 处理头像上传事件
    async handleAvatarUpload(params) {
      let file = params.file;
      const { data: res } = await this.$http.post(
        "/user/profile/avatar",
        { avatarFile: file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("param", res);
      if (res.code == "SUCCESS") {
        const frontendIP = window.location.hostname;
        // 获取前端的端口地址
        let frontendPort = window.location.port;
        this.form.avatar =
          "http://" +
          frontendIP +
          ":" +
          frontendPort +
          process.env.VUE_APP_BASE_API +
          res.data.imgUrl;
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        userInfo.avatar = this.form.avatar;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
      } else {
        this.$message.warning(res.message);
      }
    },
    // 数据初始化 表格内容同上
    async init() {
      if (!(await this.judgeLogin())) {
        localStorage.removeItem("user");
        localStorage.removeItem("userInfo");
        this.$router.push("/memberLogin");
        // 未登录
        return;
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.form.nickName = userInfo.nickName;
      this.form.email = userInfo.email;
      this.form.avatar = userInfo.avatar;
      this.form = userInfo;
      this.identify = userInfo.userType;
      this.getAllReligious();
      this.getAllOccupation();
      this.getAllRegions();
      this.getAllEducations();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 在成为作者之后 需要更新用户信息
    async getCurrentUserMessageToLocalStorage() {
      const { data: res } = await this.$http.get("/user/profile/get");
      // 只要不是默认头像 就要对获取的头像进行完善端口
      if (res.data.user.avatar != "") {
        // 登录的时候 localStorage 默认为空此时在加入时 要先对 avatar 进行修改
        const frontendIP = window.location.hostname;
        // 获取前端的端口地址
        let frontendPort = window.location.port;
        let avatar =
          "http://" +
          frontendIP +
          ":" +
          frontendPort +
          process.env.VUE_APP_BASE_API +
          res.data.user.avatar;
        res.data.user.avatar = avatar;
      }
      localStorage.setItem("userInfo", JSON.stringify(res.data.user));
    },
    // 提交成为作者
    async onSubmitToBecomeAuthor() {
      const { data: res } = await this.$http.post(
        "/user/profile/toBeAuthor",
        this.authorForm
      );
      if (res.code == "SUCCESS") {
        this.identify = 1; //变为作者的身份
        this.$message.success("成为作者成功");
        this.getCurrentUserMessageToLocalStorage();
      } else {
        this.$message.warning(res.message);
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-input__suffix {
  left: 370px;
}
.container {
  padding: 30px 50px;
  .avatar {
    width: 400px;
    height: 150px;
    display: flex;
    align-items: center;
    // justify-content: center;
    img {
      width: auto;
      height: 75px;
    }
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000000;
    font-size: 30px;
    font-weight: 600;
    .headerTitle {
      margin-right: 20px;
    }
    .line {
      height: 2px;
      width: 90%;
      background-color: #aeaeae;
    }
  }
  .content {
    margin-top: 50px;
    .tip {
      margin-bottom: 50px;
    }
    /deep/ .el-tabs--left .el-tabs__item.is-left {
      text-align: left;
      font-size: 26px;
      margin-top: 20px;
      font-weight: 600;
    }
    /deep/ .el-tabs__active-bar {
      display: none;
    }
    /deep/ .el-tabs__item.is-active {
      border-bottom: 5px solid #409eff;
    }
    /deep/ .el-tabs__item {
      padding: 0 5px;
    }
    /deep/ .el-input {
      width: 25%;
    }
    /deep/ .el-form-item__content {
      text-align: left;
    }
    .personalData {
      /deep/ .el-form-item__label {
        height: 100%;
        display: flex;
        align-items: center;
      }
      /deep/ .el-form-item:first-child {
        height: 148px;
        line-height: 148px;
      }
    }
    .formCenter {
      background-color: #fff !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /deep/ .el-upload--picture-card {
        margin-right: 250px;
      }
      /deep/ .el-input__inner {
        width: 400px;
      }
    }
  }
}
</style>
