<!--
 * @description: 
 * @param: 
 * @return: 
 * @Author: zrh
 * @Date: 2024-01-12 21:44:54
 * @LastEditors: sueRimn
 * @LastEditTime: 2024-02-15 12:40:58
-->
<!--/*
 * @file           MessageDetail
 * @description    文章详情页面
 * @author         zrh
 * @date            2024-01-12
 */ -->
<template>
  <div class="container">
    <div class="coverPic">
      <img :src="currentIdMessage.imgSrc" alt="" />
    </div>
    <div class="title">{{ currentIdMessage.name }}</div>
    <!-- header可能是图片 也可能是视频 -->
    <div class="header">
      <div class="time">
        <img src="../assets/images/date.png" alt="" />
        <span> {{ currentIdMessage.createTime }}</span>
      </div>
      <!-- <div class="author"> -->
      <!-- <img src="../assets/images/person.png" alt="" /> -->
      <!-- <span> {{ currentIdMessage.author }}</span> -->
      <!-- <span> {{ currentIdMessage.content }}</span> -->
      <!-- </div> -->
    </div>

    <div class="content" v-html="currentIdMessage.content" ref="editor"></div>
    <div class="messageTypes">
      <div
        class="messageType"
        v-for="(keyword, index) in currentIdMessage.latestKeywordList"
        :key="index"
        @click="pushIntoSearchDetail(keyword)"
      >
        {{ keyword }}
      </div>
    </div>
    <div class="download" v-if="type == 'book' || type == 'journal'">
      <div
        class="picBox"
        v-if="
          currentIdMessage.resourceType == '1' ||
          currentIdMessage.resourceType == '2'
        "
      >
        <img
          src="../assets/images/pdfWay.png"
          alt=""
          v-if="currentIdMessage.resourceType == '1'"
          @click.stop="download(currentIdMessage)"
        />
        <img
          src="../assets/images/otherWay.png"
          alt=""
          v-if="currentIdMessage.resourceType == '2'"
          @click.stop="downloadResourceUrl(currentIdMessage.resourceUrl)"
        />
      </div>
    </div>
    <!-- 至少有两篇文章 -->
    <div class="titlePart">
      <div class="title">推荐阅读</div>
      <div class="line"></div>
    </div>
    <div
      class="secondPart"
      v-for="(item, index) in list"
      :key="index"
      @click="pushIntoArticleDetail(item.id)"
    >
      <div class="leftPart">
        <div class="pink">
          <img :src="item.imgSrc" alt="" />
        </div>
      </div>
      <div class="rightPart">
        <div class="timeAndAuthorPart">
          <div class="time" style="color: #c2c2c2">
            <i class="el-icon-date"></i>
            <span style="margin-left: 20px">{{ item.createTime }}</span>
          </div>
          <!-- <div class="author" style="color: #c2c2c2">
            <i class="el-icon-user-solid"></i>
            <span style="margin-left: 20px">{{ item.author }}</span>
          </div> -->
        </div>
        <div class="title">{{ item.name }}</div>
        <div class="content">{{ item.summary }}</div>
        <div class="messageTypes">
          <div
            class="messageType"
            v-for="(keyword, index) in item.latestKeywordList"
            :key="index"
            @click="pushIntoSearchDetail(keyword)"
          >
            {{ keyword }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
export default {
  data() {
    return {
      type: null, //type用来表示 当前详情页的类型 可以是article 可以是message 最新消息,期刊,丛书
      id: null,
      list: [], //推荐文章列表
      currentIdMessage: {}, //跳转到详情页的id 所对应的文章内容
      page: {
        pageIndex: 1,
        pageSize: 4,
      },
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    if (this.type == "article") {
      this.getArticle();
      if (this.id) this.getCurrentIdArticleDetailMessage();
    } else if (this.type == "message") {
      //消息详情
      this.getMessage();
      if (this.id) this.getCurrentIdMessageDetailMessage();
    } else if (this.type == "book") {
      this.getBook();
      if (this.id) this.getCurrentIdBookDetailMessage();
    } else if (this.type == "journal") {
      this.getJournal();
      if (this.id) this.getCurrentIdJournalDetailMessage();
    } else if (this.type == "meeting") {
      this.getMeeting();
      if (this.id) this.getCurrentIdMeetingDetailMessage();
    } else if (this.type == "lecture") {
      this.getLecture();
      if (this.id) this.getCurrentIdLectureDetailMessage();
    }
  },
  methods: {
    // 路径跳转使用
    pushTo(path) {
      console.log("path", path);
      this.flag = [false, false, false, false, false];
      this.$router.push(`${path}`);
    },
    // 当信息登录信息失效之后 logOut
    async logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("userInfo");
      const { data: res } = await this.$http.get("/logout");
      if (res.code == "SUCCESS") {
        this.$message.success(res.message);
        this.pushTo("/memberLogin");
        this.isLogin = false;
      } else {
        this.$message.warning(res.message);
      }
    },
    //  在下载前判断
    async validateUserLogin() {
      const res = await this.$http.get("/user/profile/get");
      console.log("data-validate", res.data.code);
      if (res.data.code == 401) {
        this.$message({
          message: "登录信息已过期或未登录,请重新登录",
          type: "error",
        });
        this.logout();
        return false;
      } else {
        return true;
      }
    },
    setVideoInitWitdthAndHeight() {
      this.$nextTick(() => {
        const videos = document.querySelectorAll(".ql-video");
        videos.forEach((video) => {
          video.setAttribute("height", "660"); // 设置高度为360
          video.setAttribute("width", "1140"); // 设置宽度为640
        });
        video.style.display = "block";
        video.style.marginLeft = "10%";
        console.log("video--------------->", videos);
      });

      // const cinemas = document.querySelectorAll(".br-chrome");
      // console.log("iframes", cinemas);
    },
    handlerVideoOrAudioAutoplay() {
      this.setVideoInitWitdthAndHeight();
      // console.log("当前正在执行暂停操作");
      let num = 0; //num 最多40次循环
      let mediaElements = [];
      let iframes = this.$refs.editor.getElementsByTagName("iframe"); //  检查是否有iframes 这个元素
      let that = this;
      if (iframes.length > 0) {
        this.intervalId = setInterval(() => {
          iframes = that.$refs.editor.getElementsByTagName("iframe"); //  遍历所有的 iframe 元素
          for (let i = 0; i < iframes.length; i++) {
            const iframe = iframes[i];
            const iframeDoc =
              iframe.contentDocument || iframe.contentWindow?.document; // 查找音频或视频元素
            mediaElements = iframeDoc?.querySelectorAll("audio, video"); // 遍历所有的音频或视频元素
            for (let j = 0; j < mediaElements.length; j++) {
              const media = mediaElements[j];
              media.pause();
            }
            num++;
            // 加载完成  就终止定时器 仅此一次
            if (num >= 25) {
              console.log("已暂停");
              clearInterval(this.intervalId);
            }
          }
        }, 200);
      }
    },
    //  pdf 下载
    async download(item) {
      let id = item.resourceUrl.match(/\d+/g).join(""); //提取resourceUrl中的数字部分
      // 用户未登录或由于浏览器索引导致残留的残留登录 需要重置一下
      if (!(await this.validateUserLogin())) {
        return;
      }
      const res = await this.$http.get(
        "/common/downloadByTypeAndId",
        {
          type: "PDF",
          id,
        },
        { responseType: "blob" }
      );
      if (res.headers["content-type"] == "application/json;charset=utf-8") {
        // 当后台返回一个json时候 就说明 是没有权限的401情况 此时要两次拦截
        //  下载后再次进行一次校验
        this.$message({
          message: "登录信息已过期或未登录,请重新登录",
          type: "error",
        });
        return;
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "下载文件.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    // 根据资源链接下载
    downloadResourceUrl(url) {
      window.open(url, "_blank");
    },
    // 点击按钮进入搜索界面
    pushIntoSearchDetail(keyword) {
      this.$router.push({
        path: "/searchDetail",
        query: { keyword, mode: "and" },
      });
    },
    // 获取讲座数据列表
    async getLecture() {
      this.list = [];
      const { data: res } = await this.$http.get(
        "/busLectures/list",
        this.page
      );
      let newData = res.data.list;
      newData.forEach((item, index) => {
        if (this.id != item.id) {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          if (item.tagKeywords)
            item.latestKeywordList = item.tagKeywords.split(" ");
          console.log("item", item.latestKeywordList);
          this.list.push(item);
        }
      });
    },
    // 获取指定讲座具体内容
    async getCurrentIdLectureDetailMessage() {
      const { data: res } = await this.$http.get("/busLectures/getById", {
        id: this.id,
      });
      this.currentIdMessage = res.data;
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      this.currentIdMessage.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        this.currentIdMessage.coverImage;
      if (this.currentIdMessage.tagKeywords)
        this.currentIdMessage.latestKeywordList =
          this.currentIdMessage.tagKeywords.split(" ");
      this.handlerVideoOrAudioAutoplay(); //在初始化完成之后 让视频和音频取消直接播放
    },
    // 获取指定会议具体内容
    async getCurrentIdMeetingDetailMessage() {
      const { data: res } = await this.$http.get("/busAnnualMeeting/getById", {
        id: this.id,
      });
      this.currentIdMessage = res.data;
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      this.currentIdMessage.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        this.currentIdMessage.coverImage;
      if (this.currentIdMessage.tagKeywords)
        this.currentIdMessage.latestKeywordList =
          this.currentIdMessage.tagKeywords.split(" ");
      this.currentIdMessage.content = this.handlerPathAddEnvironmentPath(
        this.currentIdMessage.content
      );

      console.log("content", this.currentIdMessage.content);
      this.handlerVideoOrAudioAutoplay(); //在初始化完成之后 让视频和音频取消直接播放
    },
    // 获取会议数据列表
    async getMeeting() {
      this.list = [];
      const { data: res } = await this.$http.get(
        "/busAnnualMeeting/list",
        this.page
      );
      let newData = res.data.list;
      newData.forEach((item, index) => {
        if (this.id != item.id) {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          if (item.tagKeywords)
            item.latestKeywordList = item.tagKeywords.split(" ");
          console.log("item", item.latestKeywordList);
          this.list.push(item);
        }
      });
    },
    // 获取期刊数据列表
    async getJournal() {
      this.list = [];
      const { data: res } = await this.$http.get(
        "/busMagazine/list",
        this.page
      );
      let newData = res.data.list;
      newData.forEach((item, index) => {
        if (this.id != item.id) {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          if (item.tagKeywords)
            item.latestKeywordList = item.tagKeywords.split(" ");
          console.log("item", item.latestKeywordList);
          this.list.push(item);
        }
      });
    },
    // 获取当前id对应的期刊详情
    async getCurrentIdJournalDetailMessage() {
      const { data: res } = await this.$http.get("/busMagazine/getById", {
        id: this.id,
      });
      this.currentIdMessage = res.data;
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      this.currentIdMessage.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        this.currentIdMessage.coverImage;
      if (this.currentIdMessage.tagKeywords)
        this.currentIdMessage.latestKeywordList =
          this.currentIdMessage.tagKeywords.split(" ");
      this.currentIdMessage.content = this.handlerPathAddEnvironmentPath(
        this.currentIdMessage.content
      );
      this.handlerVideoOrAudioAutoplay(); //在初始化完成之后 让视频和音频取消直接播放
    },
    // 推荐消息列表
    async getBook() {
      this.list = [];
      const { data: res } = await this.$http.get("/busBook/list", this.page);
      let newData = res.data.list;
      newData.forEach((item, index) => {
        if (this.id != item.id) {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          if (item.tagKeywords)
            item.latestKeywordList = item.tagKeywords.split(" ");
          console.log("item", item.latestKeywordList);
          this.list.push(item);
        }
      });
    },
    // 获取当前id对应的图书详情
    async getCurrentIdBookDetailMessage() {
      const { data: res } = await this.$http.get("/busBook/getById", {
        id: this.id,
      });
      this.currentIdMessage = res.data;
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      this.currentIdMessage.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        this.currentIdMessage.coverImage;
      if (this.currentIdMessage.tagKeywords)
        this.currentIdMessage.latestKeywordList =
          this.currentIdMessage.tagKeywords.split(" ");
      this.currentIdMessage.content = this.handlerPathAddEnvironmentPath(
        this.currentIdMessage.content
      );
      this.handlerVideoOrAudioAutoplay(); //在初始化完成之后 让视频和音频取消直接播放
    },
    // 获取当前id对应的消息详情
    async getCurrentIdMessageDetailMessage() {
      const { data: res } = await this.$http.get("/busNews/getById", {
        id: this.id,
      });
      this.currentIdMessage = res.data;
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      this.currentIdMessage.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        this.currentIdMessage.coverImage;
      if (this.currentIdMessage.tagKeywords)
        this.currentIdMessage.latestKeywordList =
          this.currentIdMessage.tagKeywords.split(" ");
      this.currentIdMessage.content = this.handlerPathAddEnvironmentPath(
        this.currentIdMessage.content
      );
      this.$forceUpdate();
      this.$nextTick(() => {
        this.handlerVideoOrAudioAutoplay(); //在初始化完成之后 让视频和音频取消直接播放
      });
    },
    handlerPathAddEnvironmentPath(path) {
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      const html = path;
      const iframePattern = /<iframe.*?src="([^"]+)"/g;
      const prefix =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API; // 你想要添加的统一前缀
      const modifiedHtml = html.replace(iframePattern, (match, src) => {
        if (!src.startsWith("http://") && !src.startsWith("https://")) {
          const newSrc = prefix + src;
          return match.replace(src, newSrc);
        }
        return match;
      });
      console.log("modifiedHtml", modifiedHtml);
      return modifiedHtml;
    },
    // 推荐消息列表
    async getMessage() {
      this.list = [];
      const { data: res } = await this.$http.get("/busNews/list", this.page);
      let newData = res.data.list;
      newData.forEach((item, index) => {
        if (this.id != item.id) {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          if (item.tagKeywords)
            item.latestKeywordList = item.tagKeywords.split(" ");
          console.log("item", item.latestKeywordList);
          this.list.push(item);
        }
      });
    },
    // 推荐文章详情
    async getArticle() {
      this.list = [];
      const { data: res } = await this.$http.get("/busArticle/list", this.page);
      let newData = res.data.list;
      newData.forEach((item, index) => {
        if (this.id != item.id) {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          if (item.tagKeywords)
            item.latestKeywordList = item.tagKeywords.split(" ");
          console.log("item", item.latestKeywordList);
          this.list.push(item);
        }
      });
    },
    // type= 文章 =>根据指定的id 来查找列表
    async getCurrentIdArticleDetailMessage() {
      const { data: res } = await this.$http.get("/busArticle/getById", {
        id: this.id,
      });
      this.currentIdMessage = res.data;
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      this.currentIdMessage.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        this.currentIdMessage.coverImage;
      if (this.currentIdMessage.tagKeywords)
        this.currentIdMessage.latestKeywordList =
          this.currentIdMessage.tagKeywords.split(" ");
      this.currentIdMessage.content = this.handlerPathAddEnvironmentPath(
        this.currentIdMessage.content
      );
      this.handlerVideoOrAudioAutoplay(); //在初始化完成之后 让视频和音频取消直接播放
    },
    // pushIntoArticleDetail
    pushIntoArticleDetail(id) {
      this.id = id;
      this.$router.push({
        path: "/messageDetail",
        query: { id, type: this.type },
      });
      if (this.type == "article") {
        this.getCurrentIdArticleDetailMessage();
        this.getArticle();
      } else if (this.type == "message") {
        this.getCurrentIdMessageDetailMessage();
        this.getMessage();
      } else if (this.type == "book") {
        this.getCurrentIdBookDetailMessage();
        this.getBook();
      } else if (this.type == "journal") {
        this.getJournal();
        this.getCurrentIdJournalDetailMessage();
      } else if (this.type == "meeting") {
        this.getMeeting();
        this.getCurrentIdMeetingDetailMessage();
      } else if (this.type == "lecture") {
        this.getLecture();
        this.getCurrentIdLectureDetailMessage();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@media (min-width: 768px) {
  .container {
    box-sizing: border-box;
    width: 98vw;
    padding: 0 10vw;
    margin: 0 auto;

    .title {
      font-weight: 600;
      text-align: left;
      margin-top: 50px;
      width: 100%;
      color: #2c2c2c;
      font-size: 26px;
    }
    .header {
      width: 100%;
      height: 100px;
      .time {
        margin-top: 30px;
        width: 100%;
        display: flex;
        img {
          margin-right: 20px;
          width: 20px;
          height: 20px;
        }
      }
      .author {
        margin-top: 15px;
        display: flex;
        img {
          margin-right: 20px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .coverPic {
      margin-top: 100px;
      width: auto;
      height: 400px;
      img {
        width: auto;
        height: 100%;
      }
    }
    .contentPicOrVideo {
      width: auto;
      height: 400px;
      // background-color: pink;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .download {
      margin-top: 30px;
      display: flex;
      text-align: left;
      img {
        cursor: pointer;
        width: 150px;
        height: 75px;
      }
    }
    .content {
      margin-top: 30px;
      text-align: left;
      width: 100%;
      height: auto;
      min-height: 100px;
    }
    .titlePart {
      display: flex;
      align-items: center;
      margin-top: 50px;
      .title {
        // 消除同名权重
        margin-top: 0;
        width: auto;
        font-weight: 600;
        font-size: 36px;
        color: #000;
      }
      .line {
        margin-left: 20px;
        flex-grow: 1;
        height: 4px;
        background-color: #d2d2d2;
      }
    }

    .messageTypes {
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      text-align: left;
      margin: 10px 0;
      font-weight: 650;
      color: #3f387f;
      font-size: 20px;
      .messageType {
        margin-right: 20px;
        cursor: pointer;
      }
      .messageType:hover {
        color: red;
      }
    }
    .secondPart {
      margin-top: 20px;
      padding: 20px;
      display: flex;
      width: calc(100% - 20px);
      height: 300px;
      background-color: #efefef;
      cursor: pointer;
      .leftPart {
        padding: 30px;
        background-color: #fff;
        height: calc(100% - 80px);
        width: calc(30% - 5vw);
        width: 450px;
        max-width: 450px;
        min-width: 450px;
        min-height: 220px;
        max-height: 220px;
        height: 220px;
        .pink {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .rightPart {
        padding: 30px;
        background-color: #fff;
        height: calc(100% - 80px);
        width: calc(70% - 175px);
        .timeAndAuthorPart {
          display: flex;
          // margin: 50px 30px;
          .time {
            margin-right: 30px;
          }
        }
        .title {
          margin: 15px 30px;
          text-align: left;
          font-weight: 600;
          font-size: 30px;
        }
        .secondTitle {
          text-align: left;
          margin: 20px 30px;
          font-size: 30px;
        }
        .content {
          text-align: left;
          margin: 0 30px;
          margin-top: 15px;
          height: 30%;
          word-break: break-all;
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .container {
    padding: 40px 5vw 0 5vw;
    background-color: #fafafa;
    .content {
      margin-top: 30px;
      text-align: left;
      width: 100vw;
      height: auto;
      min-height: 100px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-weight: 600;
      text-align: left;
      margin-top: 50px;
      width: 100%;
      color: #2c2c2c;
      font-size: 26px;
    }
    .header {
      width: 100%;
      height: 100px;
      .time {
        margin-top: 30px;
        width: 100%;
        display: flex;
        img {
          margin-right: 20px;
          width: 20px;
          height: 20px;
        }
      }
      .author {
        margin-top: 15px;
        display: flex;
        img {
          margin-right: 20px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .contentPicOrVideo {
      width: 100vw;
      height: 400px;
      img {
        width: 100vw;
        height: auto;
      }
    }
    .download {
      margin-top: 30px;
      display: flex;
      text-align: left;
      img {
        cursor: pointer;
        width: 150px;
        height: 75px;
      }
    }
    .secondPart {
      margin-top: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      width: 78vw;
      height: 76vh;
      background-color: #efefef;
      cursor: pointer;
      .leftPart {
        padding: 30px;
        background-color: #fff;
        height: calc(100% - 80px);
        width: calc(83vw - 80px);
        .pink {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .rightPart {
        background-color: #fff;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        .timeAndAuthorPart {
          text-align: left;
          padding: 0 10vw;
          width: 80%;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .time {
            margin-right: 20px;
          }
        }
        .title {
          text-align: left;
          font-weight: 600;
          padding: 0 20px;
          font-size: 30px;
          margin: 10px 0;
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .secondTitle {
          text-align: left;
          margin: 20px 30px;
          font-size: 30px;
        }
        .content {
          text-align: left;
          margin: 10px 30px;
          height: 10%;
          word-break: break-all;
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .messageTypes {
      display: flex;
      text-align: left;
      flex-wrap: wrap;
      margin: 20px 30px;
      font-weight: 650;
      color: #3f387f;
      font-size: 20px;
      width: 80%;

      .messageType {
        margin-right: 20px;
        cursor: pointer;
        text-align: left;
        // margin-left: 10vw;
      }
      .messageType:hover {
        color: red;
      }
    }
  }
}
</style>
